.Container {
    position: relative;
}

.Options {
    padding: 15px;
    box-sizing: border-box;
    background-color: #fff;
    color: #8a8a8a;
    border-radius: 6px;
    position: absolute;
    right: 0px;
    top: 0;
    width: 220px;
    box-shadow: -4px 4px 27px 0px rgba(0,0,0,0.15);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 100ms ease-in-out;
}

.Options .Name {
    padding-right: 50px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
}

.Options ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.Options li {
    margin: 8px 0;
    width: 100%;
    
}

.Options a {
    font-size: 12px;
    font-weight: 400;
    color: #8a8a8a;
    text-decoration: none;
    height: 100%;
    width: 100%;
    /* box-sizing: border-box; /* To make sure the height includes the padding and the border. */
}

.ProfileImage {
    cursor: pointer;
    position: absolute;
    padding: 5px 10px;
    right: 0px;
    z-index: 1000;
}

.ProfileImage:hover~.Options, .Options:hover {
    opacity: 1;
    visibility: visible;
    transition: 100ms ease-in-out;
}

.Email {
    color: var(--mediumGray);
    font-family: "Helvetica Neue";
    font-size: 12px;
    line-height: 14px;
    padding-top: 2px;
  }