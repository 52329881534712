.Container {
    min-width: 30px;
    min-height: 18px;
    font-size: 10px;
    color: white;
    font-weight: 500;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 0;
    margin: 1px 1px;
}

.Blue {
    background-color: var(--markliiBlue);
}

.Green {
    background-color: var(--markliiGreen);
}

.Blank {
    color: inherit;
    background-color: white;
    border: 1px solid #e8e8e8;
}

.Red {
    background-color: var(--markliiRed);
}

.TagText {
    flex-grow: 2;
    margin: 0 5px;
    cursor: default;
    white-space: nowrap;
}

.RemoveBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 6px 6px;
    border-radius: 6px;
    cursor: pointer;
}

.RemoveBox > img {
    width: 8px;
    height: 8px;
}

.Blank .RemoveBox {
    background-color: rgba(0, 0, 0, 0.01);
    border-left: 1px solid #e8e8e8;
}

.Blank .RemoveBox > img {
    filter: invert(50%);
}

.Pointer {
    cursor: pointer;
}