.Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.SaveButton {
    width: 120px;
}

.List {
    padding: 10px;
    box-sizing: border-box;
    height: 200px;
    width: 400px;
    overflow-y: scroll;
}

.ListItem {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border: 1px solid #fff;
}

.ListItem:hover {
    background-color: #f5f5f5;
}

.LeftDetails, .RightDetails {
    display: flex;
    flex-flow: column nowrap;
}

.RightDetails {
    text-align: right;
}

.StoreName {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 500;
}

.SmallDetails {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 300;
}

.SelectedItem {
    background-color: #fcfcfc;
    border: 1px solid var(--markliiGreen);
}

.SelectedItem .StoreName {
    font-weight: 700;
}

.SmartSuggestionContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
    padding: 0 10px;
}

.SmartSuggestion {
    color: var(--markliiGreen);
    cursor: pointer;
    font-weight: 500;
    padding-left: 5px;
}

.SmartSuggestion:hover {
    text-decoration: underline;
}