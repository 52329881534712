.NavigationItem {
    margin: 2px 0;
    width: 100%;
    height: 43px;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0.9px;
}

/* Needed so elements inside li stay aligned between themselves. */
.NavigationItem div {
    width: 100%;
}

.NavigationItem a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    transition: 100ms linear;
    /* box-sizing: border-box; /* To make sure the height includes the padding and the border. */
}

.NavigationItem img {
    margin: 0px  15px 0px 30px;
    width: 20px;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: #a2d856;
    background-color: RGBA(255, 255, 255, 0.15);
    border-radius: 0px 6px 6px 0px;
    transition: 100ms linear;
}

.NavigationItem .active {
    font-weight: 700;
}

.Notification {
    background-color: RGB(255, 255, 255);
    color: #9CCD33;
    border-radius: 3px;
    max-width: 40px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0;
    font-size: 0.7rem;
    font-weight: 700;
    margin-right: 6px;
}




/* FOR DESKTOP DEVICES */

@media (max-width: 500px) {
    .NavigationItem img {
        margin: 0px  15px 0px 20px;
    }

    .NavigationItem {
        margin: 10px 0;
        width: 100%;
        height: 43px;
        font-size: 1rem;
        font-weight: 400;
    }

    .NavigationItem a.active {
        border: 1px solid #fff;
        border-left-width: 0;
    }
}