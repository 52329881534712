.Container {
    padding: 5px;
    box-sizing: border-box;
}

.TableContainer table {
    max-width: 100%;
    border-collapse: collapse;
}

.TableContainer td, th {
    padding: 0.25rem;
    padding-right: 15px;
    text-align: left;
}

/*** Body line */
.TableContainer tbody tr {
    font-size: 12px;
    line-height: 12px;
    color: var(--darkGray);
    height: 35px;
    cursor: pointer;
    border-bottom: 1px solid white;
}

.TableContainer tbody tr td:first-child { border-top-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-top-right-radius: 10px; }
.TableContainer tbody tr td:first-child { border-bottom-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-bottom-right-radius: 10px; }

.TableContainer tbody tr:hover {
    background-color: #F7F7F7;
}

.TopButtons {
    width: 100%;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.TopButtons div {
    min-width: 70px;
    width: auto;
    flex-grow: 0;
}

.isNotRead {
    width: 6px;
    height: 6px;
    background-color: var(--markliiBlue);
    border-radius: 3px;
}

.OrderIconNewerFirst {
    width: 12px;
    padding: 0 5px;
    cursor: pointer;
}

.OrderIconOlderFirst {
    width: 12px;
    padding: 0 5px;
    cursor: pointer;
    transform: rotateX(180deg);
}