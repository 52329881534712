.Toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40px;
    margin: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.Toggle img {
    height: 16px;
}

.Closed img {
    transform: rotateY(180deg);
    transform-origin: 50% 50%;
    transition: transform 300ms ease-in-out;
}

.Opened img {
    transition: transform 300ms ease-in-out;
}

.Hidden {
    display: none;
}