.Container {
    display: flex;
    flex-flow: column nowrap;
    width: 540px;
    height: 100%;
    padding: 0;
}

.Header {
    background-color: #F7F7F7;
    min-height: 200px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.GS1Info {
    min-height: 15px;
    width: auto;
    border-radius: 0 0 6px 6px;
    margin: 0 10px;
    box-sizing: border-box;
    background-color: #E3E3E3;
}

.Fields {
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
}

.HorizontalFlex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.Tags {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 5px;
}

.SmallDetails {
    font-size: 10px;
    color: var(--mediumGray);
}

.ContentBox {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.TopLabel {
    font-size: 18px;
    line-height: 40px;
    letter-spacing: 1.13px;
    font-weight: 300;
    white-space: nowrap;
    margin-left: 10px;
}

.TotalWeight {
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    white-space: nowrap;
    padding-left: 10px;
    box-sizing: border-box;
    min-width: 180px;
}

.SideBySideLists {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    font-size: 11px;
}

.SideBySideDiv {
    display: flex;
    flex-flow: row nowrap;
    margin: 1px 0;
}

.SideBySideDiv div {
    min-width: 90px;
}
.HorizontalList li {
    display: inline;
}

.SideBySideLists .Bold {
    font-weight: 500;
}

.GTINInput {
    max-width: 250px;
}

.LinkToGuidelines {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 6px;
}