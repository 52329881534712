.Container {
    padding: 5px;
    box-sizing: border-box;
}

.TopButtons {
    width: 100%;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.TopButtons div {
    min-width: 70px;
    width: auto;
    flex-grow: 0;
}

.TableContainer {
    padding-top: 20px;
    box-sizing: border-box;
}

.TableContainer table {
    max-width: 100%;
    border-collapse: collapse;
}

/*** Table lines and cells */
.TableContainer td, th {
    padding: 0.25rem;
    padding-right: 10px;
    text-align: left;
    /* border: 1px solid rgb(230, 230, 230); */
}

/*** Header line */
.TableContainer thead th {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75;
    color: var(--darkGray);
}

/*** Body line */
.TableContainer tbody tr {
    font-size: 12px;
    line-height: 12px;
    color: var(--darkGray);
    height: 35px;
    cursor: pointer;
    border-bottom: 1px solid white;
    /* border-bottom: 1px solid rgb(231, 231, 231); */
}

.TableContainer tbody tr td:first-child { border-top-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-top-right-radius: 10px; }
.TableContainer tbody tr td:first-child { border-bottom-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-bottom-right-radius: 10px; }

.TableContainer tbody tr:hover {
    background-color: #F7F7F7;
}

.SelectedRow {
    background-color: rgb(250, 250, 250);
}

.TagsContainer {
    display: flex;
    flex-flow: row wrap;
}

.NarrowColumn {
    width: 30px;
}

.RemoveBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    background-color: var(--markliiBlue);
    padding: 6px 6px;
    border-radius: 6px;
    cursor: pointer;
}

.RemoveBox > img {
    width: 8px;
    height: 8px;
}

.SettingsContainer {
    position: relative;
    border-radius: 6px;
    border: 1px solid white;
}

.SettingsIcon {
    height: 24px;
    cursor: pointer;
}

.SettingsContainer:hover {
    border: 1px solid var(--mediumGray);
}

.SettingsBox {
    width: 300px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #fff;
    color: #8a8a8a;
    border-radius: 6px;
    position: absolute;
    left: 38px;
    top: -5px;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.15);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 100ms ease-in-out;
}

.SettingsOpen {
    opacity: 1;
    visibility: visible;
    transition: 100ms ease-in-out;
}

.PropertyList {
    display: flex;
    flex-flow: column nowrap;
}

.PropertyOption {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    font-weight: 400;
    font-size: 13px;
}

.PropertyOption input {
    margin: 0 10px;
}

.ByWeightIcon {
    height: 14px;
}

.ByWeightIconRow {
    height: 9px;
    opacity: 0.8;
}

.IsFemaleIcon {
    height: 18px;
}

.IsFemaleIconRow {
    height: 13px;
    opacity: 0.8;
}

.Null {
    color: var(--lightGray);
    font-size: 10px;
}

.Code {
    font-size: 10px;
}

.Incomplete {
    margin: auto;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: var(--markliiRed);
}

.NoLineBreak {
    white-space: nowrap;
}

.EditItemContainer {
    position: relative;
    height: 100%;
}

.EditItem {
    z-index: 150;
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: calc(100% - 110px);
}

.CenterDiv {
    display: flex;
    justify-content: center;
}

.LinkToGuidelines {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}