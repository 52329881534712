.ButtonDiv {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.ButtonDiv button {
    width: 100%;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 20px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

        .ButtonDiv button:disabled {
            background-color: #f5f5f5;
            cursor: not-allowed;
            border: 1px solid #e8e8e8;
            color: var(--lightGray);
            font-weight: 400;
        }

        .ButtonDiv button:not(:disabled) {
            animation: enable 0.3s linear;
        }


.Primary {
    background-color: var(--markliiGreen);
    color: #fff;
    transition: background-color 150ms linear;
    border: 0;
    border: 1px solid var(--markliiGreen);
    font-weight: 500;
}

        .Primary:hover, .Primary:focus {
            background-color: #b1d85c;
            border: 1px solid #b1d85c;
            transition: 150ms linear;
        }

        .Primary:active {
            background-color: #67cf4b;
            border: 1px solid #67cf4b;
            transition: 300ms linear;
        }

.Default {
    background-color: #fff;
    color: inherit;
    border: 1px solid #e8e8e8;
    transition: 150ms linear;
}

        .Default:hover, .Default:focus {
            color: var(--markliiGreen);
            border: 1px solid var(--markliiGreen);
            transition: 150ms linear;
        }

        .Default:active {
            color: #67cf4b;
            border: 1px solid #67cf4b;
            transition: color 300ms linear;
        }

.RedAlert {
    background-color: var(--markliiRed);
    color: #fff;
    border: 1px solid #fff;
    transition: 150ms linear;
}

.ButtonDiv button.Small {
    font-size: 11px;
    line-height: 11px;
    padding: 8px 10px;
    white-space: nowrap;
}

.Icon {
    height: 14px;
    
    box-sizing: border-box;
}

.IconFlipped {
    height: 14px;
    transform: rotate(180deg);
    box-sizing: border-box;
}

.Text {
    padding: 0 6px;
}