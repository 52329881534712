
.TopButtons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.TableContainer {
    padding-top: 20px;
    box-sizing: border-box;
}

.TableContainer table {
    max-width: 100%;
    border-collapse: collapse;
}

/*** Table lines and cells */
.TableContainer td, th {
    padding: 0.25rem;
    padding-right: 10px;
    text-align: left;
    /* border: 1px solid rgb(230, 230, 230); */
}

/*** Header line */
.TableContainer thead th {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.75;
    color: var(--darkGray);
}

/*** Body line */
.TableContainer tbody tr {
    font-size: 12px;
    line-height: 12px;
    color: var(--darkGray);
    height: 35px;
    cursor: pointer;
    border-bottom: 1px solid white;
    /* border-bottom: 1px solid rgb(231, 231, 231); */
}

.TableContainer tbody tr td:first-child { border-top-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-top-right-radius: 10px; }
.TableContainer tbody tr td:first-child { border-bottom-left-radius: 10px; }
.TableContainer tbody tr td:last-child { border-bottom-right-radius: 10px; }

.TableContainer tbody tr:hover {
    background-color: #F7F7F7;
}

.SelectedRow {
    background-color: rgb(250, 250, 250);
}