.ProfileCircle {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--markliiGreen);
    color: #fff;
    overflow: hidden;
    border: 3px solid white;
}

.Green {
    background-color: var(--markliiGreen);
    border: 3px solid var(--markliiGreen);
}

.Yellow {
    background-color: var(--markliiYellow);
    border: 3px solid var(--markliiYellow);
}

.Red {
    background-color: var(--markliiRed);
    border: 3px solid var(--markliiRed);
}

.Gray {
    background-color: #E0E0E0;
    border: 3px solid white;
}

.ProfileCircle object {
    width: 100%;
    object-fit: cover;
}

.Initials {
    font-size: 14px;
    text-align: center;
    color: #fff;
    font-weight: 400;
}