.Container {
    width: 90%;
    padding-top: 20px;
    box-sizing: content-box;
}

.Title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.TotalAmount {
    font-size: 14px;
    font-weight: 300;
    margin-right: 10px;
}

@media all and (min-width: 1000px) {

    .Container {
        width: 400px;
        padding-top: 20px;
        box-sizing: border-box;
        overflow: scroll;
    }

    .ProductList {
        height: 85%;
        overflow-y: scroll;
    }

}