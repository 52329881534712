.Container {
    cursor: pointer;
    box-sizing: border-box;
}

.TopContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
}

.TopContainer > * {
    margin: 5px;
}

.BottomContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.MainContainer {
    flex-grow: 2;
    flex-shrink: 2;
    margin-bottom: 15px;
}

    .PurchaserName {
        font-size: 14px;
    }

    .StoreName {
        margin-top: 4px;
        color: var(--mediumGray);
        font-size: 12px;
    }

    .SmallLabel {
        margin-top: 10px;
        color: var(--mediumGray);
        font-size: 10px;
        height: 12px;
    }

.PriceContainer {
    flex-grow: 0;
}

    .PriceTag {
        display: flex;
        align-items: flex-start;
    }

    .Price {
        font-size: 18px;
        font-weight: 300;
    }

    .Currency {
        margin-right: 2px;
        font-size: 10px;
        font-weight: 300;
        line-height: 10px;
    }

.ProfileContainer {
    flex-grow: 0;
    flex-shrink: 1;
}

    .ProfileCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: var(--markliiGreen);
    }

    .ProfileContainer .Initials {
        font-size: 22px;
        text-align: center;
        color: #fff;
        font-weight: 300;
    }

