.Container {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: left;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

.MainColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: white;
}

.Canvas {
    padding: 20px 20px 20px 20px;
    background-color: white;
    box-sizing: border-box;
    width: auto;
}

/* Large screens */
@media all and (min-width: 600px) {

    .Canvas {
        height: 100%;
        overflow-y: scroll; 
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        -webkit-box-sizing: border-box;
    }

}