.Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.Title {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 20px;
}

.Metric {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 20px;
}

.Horizontal {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px 5px 0 20px;
}

.Variation {
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    white-space: nowrap;
}

.Negative {
    color: var(--markliiRed);
}

.Positive {
    color: var(--markliiGreen);
}

.Description {
    font-size: 10px;
    color: rgb(170, 170, 170);
}