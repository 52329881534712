.Container {
    border-radius: 6px;
    overflow: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--lightGray);
    -webkit-overflow-scrolling: touch;
}

.Container img {
    width: 100%;
}