/* Mobile first */

.Container {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
}

.StepsContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 10px 0;
    min-height: 60px;
    flex-shrink: 0;
}

.TopButtonsDiv {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.PurchaseInformation {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.MainContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.RightSide {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    box-sizing: border-box;
    flex-shrink: 0;
}

.LeftSide {
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
    width: 100%;
}

.MiddleGap {
    flex: 1 0 50px;
    min-width: 50px;
    box-sizing: border-box;
}

.RightSide > h3 {
    padding: 10px 0;
}

.ReceiptSection {
    height: 150px;
    margin-bottom: 20px;
    max-width: 100%;
}

.NFeKey {
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    font-size: 10.5px;
}

.SaveButton button {
    width: 50px;
    font-size: 12px;
}

.PurchaseProcessedLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 20px;
    font-weight: 300;
}

/* Medium screens */
@media all and (min-width: 600px) {

    .MainContainer {
        flex-flow: row wrap;
    }

    .StepsContainer {
        flex-shrink: 0;
    }

    .LeftSide, .RightSide {
        align-items: center;
        margin-bottom: 20px;
    }

    .RightSide {
        width: 100%;
    }

    .PurchaseInformation {
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
    }

    .ReceiptSection {
        max-width: 100%;
    }

    .TopButtonsDiv {
        flex-flow: row wrap;
    }
    
}

/* Large screens */
@media all and (min-width: 1000px) {

    .TopButtonsDiv {
        flex-flow: row nowrap;
    }

    .MainContainer {
        flex-flow: row nowrap;
    }

    .ReceiptSection {
        height: 100%;
        max-height: 150px;
        max-width: 100%;
    }

    .LeftSide {
        flex-basis: 450px;
    }

    .RightSide {
        display: flex;
        flex-shrink: 1;
        justify-content: flex-start;
        flex-flow: column nowrap;
        align-items: flex-end;
        width: 100%;
        max-width: 580px;
        min-width: 300px;
        height: 100%;
    }
    
}
