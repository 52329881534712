.Header {
    height: 70px;
    display: flex;
    width: 100%;
    justify-content: baseline;
    align-items: center;
    /* padding: 0 20px; */
    box-sizing: border-box;
    z-index: 90;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.12);
    flex-shrink: 0;
}

.Header nav {
    width: 100px;
}

.PageTitle {
    color: #7a7a7a;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
}

.Profile {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}