.Sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0.9;
    height: 100%;
    width: 280px;
    background: rgb(103,207,75);
    background: linear-gradient(142deg, #67CF4B 10%, #b7d227 100%);
    color: #ffffff;
    z-index: 95;
    opacity: 1;
    transition: width 400ms ease-in-out;
    transition: visibility 0.5s, opacity 1000ms linear;
}

.Hidden {
    transition: 400ms ease-in-out;
    width: 0px;
}

.Hidden * {
    visibility: hidden;
    opacity: 0;
    transition: visibility 200ms, opacity 100ms linear;
}

.Visible {
    max-width: 90%;
    width: 280px;
    flex-shrink: 0;
    transition: width 400ms ease-in-out;
}

/* For mobile */
@media (min-width: 320px) and (max-width: 768px) {
    .Sidebar, .Visible, .Hidden {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.Sidebar * {
    transform: translateX(0);
    opacity: 1;
    transition: width 600ms ease-in-out;
}

.TopHeader {
    display: flex;
    align-items: center;
    width: 100%;
}

.Logo {
    height: 38px;
    width: 100%;
    margin: 20px 0;
}

.Sidebar nav {
    width: 100%;
    flex-grow: 1;
}


.ToggleSidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    padding: 5px;
    box-sizing: content-box;
    margin-right: 10px;
    border-radius: 6px;
    cursor: pointer;
}

.ToggleSidebar img {
    height: 18px;
    /* transform: scale(-1,-1); */
}

.Version {
    font-size: 9px;
    padding: 10px;
}