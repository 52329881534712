.Input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.InputElement {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--darkGray);
    display: block;
    width: 100%;
    height: 36px;
    outline: none;
    border: 1px solid #e8e8e8;

    border-radius: 6px;
    background-color: #fff;
    font: inherit;
    padding: 6px 10px;
    box-sizing: border-box;
}

.InputElement::placeholder {
    color: var(--lightGray);
}

.InputElement:focus {
    outline: none;
    border-color: var(--markliiGreen);
}

.Invalid {
    background-color: rgb(238, 106, 87, 0.05);
    border: 1px solid var(--markliiRed);
}

.Checkbox {
    width: 25px;
    height: 25px;
    border: 2px solid var(--lightGray);
    border-radius: 6px;
    appearance: none;
    transition: background-color 50ms linear;
}

.Checkbox:checked {
    background-color: var(--markliiGreen);
    border-color: var(--markliiGreen); 
    transition: background-color 50ms linear;
}

.Checkbox:focus {
    outline: none;
    border-color: var(--markliiGreen);
}