/* Mobile first */
.FlexContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.FlexContainer > * {
    flex: 1 100%;
}

.StatsSection {
    order: 2;
    min-width: 250px;
}

.StatsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.StatsContainer > * {
    width: 250px;
    /* flex-grow: 1; */
}

.PurchasesSection {
    order: 1;
    min-width: 250px;
}

/* Medium screens */
@media all and (min-width: 600px) {
    .FlexContainer > * { flex: 1 20%; }
    .StatsSection, .PurchasesSection { order: 1 }
}

/* Large screens */
@media all and (min-width: 1000px) {
    .FlexContainer > * { flex: 1 auto;}
    .StatsSection { width: 60% }
    .PurchasesSection { 
        width: 40%;
        height: 100%;
        overflow-y: scroll;
    }
}
