.Container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 5px 10px;
    box-sizing: border-box;
}

.TopLabel {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 1.13px;
    font-weight: 300;
    margin-bottom: 6px;
}

.BottomLabel {
    font-size: 12px;
    color: var(--lightGray);
    font-weight: 400;
}