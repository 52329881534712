.Container {
    width: 100vw;
    height: 100vh;
    /* box-sizing: content-box; */
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 500px;
    min-width: 350px;
}

.AuthBox {
    /* height: 200px; */
    width: 340px;
    max-width: 80%;
    flex-grow: 0;
    padding: 40px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.Logo {
    flex-grow: 0;
    height: 50px;
    margin: 6%;
}

@media (min-width: 600px) {
    .AuthBox {
        padding: 40px 50px;
    }
}
