.InsideContainer {
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.Shaker {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

.Inputs {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;    
}

.Inputs input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--darkGray);
    display: block;
    width: 100%;
    height: 28px;
    outline: none;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background-color: #fff;
    font: inherit;
    font-size: 12px;
    padding: 3px 6px;
    box-sizing: border-box;
}

.CleanInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--darkGray);
    display: block;
    width: 100%;
    height: 20px;
    outline: none;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background-color: #fff;
    font: inherit;
    font-size: 12px;
    padding: 3px 3px;
    box-sizing: border-box;
}

.Inputs input::placeholder {
    color: var(--lightGray);
}

.Inputs input:focus {
    outline: none;
    border-color: var(--markliiGreen);
}

    .ItemNumber {
        max-width: 30px;
        min-width: 30px;
        text-align: center;
        padding: 0;
        font-size: 11px;
    }

    .ItemCode {
        min-width: 120px;
    }

        .InputCodeItem {
            width: calc(100% - 90px);
        }

        .InputCodeItem .Hidden {
            display: none;
        }

        .SuggestionsContainer {
            position: relative;
        }

        .Suggestions {
            border-radius: 0 0 6px 6px;
            min-width: calc(100% - 8px);
            background-color: white;
            border: 1px solid #e8e8e8;
            border-width: 0 1px 1px 1px;
            position: absolute;
            padding: 5px 0px;
            margin: 0 4px;
            box-sizing: border-box;
            font-size: 11px;
            white-space: nowrap;
        }

        .Suggestions ul {
            overflow-y: scroll;
            height: 100px;
        }

        .SuggestionsHeader {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding: 0 5px 5px 10px;
            box-sizing: border-box;
            font-weight: 700;
            font-size: 10px;
        }

            .SuggestionsHeader .Link {
                cursor: pointer;
                font-weight: 400;
            }

            .SuggestionsHeader .Link:hover {
                text-decoration: underline;
            }

        .Suggestions ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .Suggestions li {
            padding: 5px 10px;
            margin: 0;
            cursor: pointer;
        }

        .Suggestions .Preselected {
            background-color: #f8f8f8;
        }

    .ItemQuantity {
        max-width: 50px;
        min-width: 50px;
    }

    .ItemPrice, .ItemDiscount {
        max-width: 70px;
        min-width: 65px;
    }

.InputItem {
    display: flex;
    flex-flow: column nowrap;
    padding: 2px;
    box-sizing: border-box;
}

.Label {
    font-size: 9px;
}

.ProductLabel {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.DetailsLabel {
    font-size: 12px;
    margin-bottom: 5px;
}

.LightLabel {
    color: #c4c4c4;
}

.TagsDiv {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.Tag {
    font-size: 8px;
    font-weight: 500;
    white-space: wrap;
    padding: 0px 6px;
    color: #fff;
    border-radius: 4px;
    height: 12px;
    line-height: 12px;
    display: none;
}

.GTIN {
    background-color: var(--markliiGreen);
    
}

.CustomCode {
    background-color: var(--markliiBlue);
}

.Visible {
    display: block;
}

.ProductInformation {
    padding: 10px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    min-height: 70px;
}

.Buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.HorizontalDisplay {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.PriceTag {
    width: 100%;
    height: 100%;
    max-height: 200px;
}

/* Medium screens */
@media all and (min-width: 600px) {
    .Container {
        max-width: 700px;
    }

    .InsideContainer {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .Buttons {
        flex-flow: row wrap;
        width: 160px;
        margin-left: 50px;
    }

    .Inputs {
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .InputCodeItem {
        width: 100%;
    }
    
}

/* Large screens */
@media all and (min-width: 1000px) {

    .Container {
        /* max-width: 100%; */
        max-width: 450px;
    }

    .InsideContainer {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .Buttons {
        margin: auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
    }
   
}