.ProductList {
    height: 100%;
    width: 500px;
}

.TableContainer {
    max-height: 80%;
    overflow-y: scroll;
    padding-right: 20px;
}

.ProductList table {
    border-collapse: collapse;
    width: 100%;
}

.ProductList table td {
    text-align: center;
    vertical-align: top;
    height: 40px;
}
.ProductList table th {
    font-size: 12px;
    font-weight: 400;
    color: #B3B3B3;
    margin: 0;
}

.ProductList table .AlignLeft {
    text-align: left;
    vertical-align: text-top;
    padding-left: 3px; 
}

.FoundProduct {
    color: var(--markliiGreen);
    font-weight: 600;
    text-align: left;
    vertical-align: text-top;
    padding-left: 3px; 
}

.ItemNumber {
    font-size: 10px;
    color: #BBBBBB;
}

.GTIN {
    font-size: 10px;
    color: #595959;
}

.Currency {
    font-size: 11px;
}

.ProductDescription {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
}

.ProductDetails {
    font-size: 10px;
    color: #BBBBBB;
    text-align: left;
    padding-left: 3px;
}

.ProductFoundIcon {
    width: 12px;
    margin-top: 2px;
}

.RemoveIcon {
    width: 15px;
    margin: 2px;
    margin-left: 5px;
    cursor: pointer;
}

.ProductList input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--darkGray);
    display: block;
    width: 100%;
    outline: none;
    font: inherit;
    border: 1px solid white;
    border-radius: 4px;
    padding: 0 2px;
    box-sizing: border-box;
}

.ProductList input[type=text]:focus {
    outline: none;
    border-color: var(--markliiGreen);
}

.GTIN input[type=text] {
    max-width: 95px;
}

.ItemNumber input[type=text] {
    max-width: 20px;
}

.Currency input[type=text] {
    max-width: 35px;
    text-align: center;
    border-radius: 4px;
    padding: 0 2px;
    border: 1px solid #ececec;
}

.Tag {
    font-size: 7px;
    font-weight: 500;
    white-space: wrap;
    color: #fff;
    border-radius: 4px;
    height: 11px;
    line-height: 11px;
    margin-top: 2px;
    padding: 0 2px;
}

.TagGTIN {
    background-color: var(--markliiGreen);
    width: 25px;
}

.TagGTINInvalid {
    background-color: var(--markliiRed);
    width: 50px;
}

.TagStoreCode {
    background-color: var(--markliiBlue);
    width: 35px;
}

.NextStepButton {
    padding-top: 20px;
}

.ProductAlreadyAdded * {
    color: #d1d1d1;
    font-weight: 400;
}

.ProductAlreadyAdded .Tag {
    color: rgb(134, 134, 134);
    font-weight: 500;
    width: 60px;
    background-color: #e6e6e6;
}

.ProductAlreadyAdded input[type=text] {
    border: 1px solid white;
    color: #d1d1d1;
}

.ProductAlreadyAdded .Currency input[type=text] {
    display: none;
}