.Container {
    position: relative;
    height: 104%;
}

.Piggy {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}