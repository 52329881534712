.Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.SaveButton {
    width: 120px;
}

.SmartSuggestionContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
    padding: 0 10px;
}

.SmartSuggestion {
    color: var(--markliiGreen);
    cursor: pointer;
    font-weight: 500;
    padding-left: 5px;
}

.SmartSuggestion:hover {
    text-decoration: underline;
}