.Name {
    font-size: 13px;
    font-weight: 400;
    margin-right: 20px;
    margin-bottom: 2px;
}

.UserSince {
    font-size: 10px;
    font-weight: 400;
}

.LastSeen {
    font-size: 12px;
    font-weight: 400;
}

.Uid {
    font-size: 8px;
    font-weight: 400;
    color: #ffffff;
}

.UserRow {
    vertical-align: top;
}