.InputText {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--darkGray);
    display: block;
    height: 25px;
    outline: none;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background-color: #fff;
    font: inherit;
    padding: 6px 6px;
    box-sizing: border-box;
    min-width: 30px;
}

.InputElement::placeholder {
    color: var(--lightGray);
}
  
.InputText:focus {
    outline: none;
    border-color: var(--markliiGreen);
}

.Invalid {
    background-color: rgb(238, 106, 87, 0.05);
    border: 1px solid var(--markliiRed);
}







/*** Suggestions Styles ***/

.SuggestionsContainer {
    position: relative;
}

.Suggestions {
    border-radius: 6px;
    min-width: calc(100% - 8px);
    background-color: white;
    border: 1px solid #e8e8e8;
    border-width: 1px 1px 1px 1px;
    position: absolute;
    top: 2px;
    padding: 5px 0px;
    box-sizing: border-box;
    font-size: 11px;
    white-space: nowrap;
    z-index: 100;
}

.Suggestions ul {
    overflow-y: scroll;
    max-height: 150px;
}

.SuggestionsHeader {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 0 5px 5px 10px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 10px;
}

.SuggestionsHeader .Link {
    cursor: pointer;
    font-weight: 400;
}

.SuggestionsHeader .Link:hover {
    text-decoration: underline;
}

.Suggestions ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Suggestions li {
    line-height: 25px;
    padding: 0px 10px;
    padding-right: 20px;
    margin: 0;
    cursor: pointer;
}

.Suggestions .Preselected {
    background-color: #f8f8f8;
}

.Hidden {
    display: none;
}