.Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 75vh;
    font-size: 10px;
    color: rgb(90, 90, 90);
}

.Logo {
    width: 40px;
    margin-bottom: 100px;
}

.Loading {
    width: 24px;
    margin-bottom: 5px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(359deg);
    }
    to {
        transform: rotate(0deg);
    }
}