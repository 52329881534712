.Container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    margin: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

    .Container:hover {
        background-color: #f8f8f8;
    }

.StepCircle {
    border-radius: 100%;
    width: 16px;
    height: 16px;
    margin: 4px;
    box-sizing: border-box;
    border: 1px solid var(--lightGray);
    color: var(--lightGray);
    font-size: 10px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .StepCircle.Current {
        border: 1px solid var(--markliiBlue);
        background-color: var(--markliiBlue);
        color: #fff;
    }

    .StepCircle.Done {
        border: 1px solid var(--markliiGreen);
        color: var(--markliiGreen);
    }

    .StepCircle.Error {
        border: 1px solid var(--markliiRed);
        color: var(--markliiRed);
    }

.Waiting, .Current, .Done, .Error {
    opacity: 1;
}

.TitleBox {
    margin: 4px;
    box-sizing: border-box;
}

    .Title.Current {
        color: inherit;
    }

    .Title.Error {
        color: var(--markliiRed);
    }

.Title {
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 12px;
    color: var(--mediumGray);
}

.Description {
    color: var(--mediumGray);
    font-size: 10px;
    line-height: 16px;
    display: none;
}

    .Description.Error {
        color: var(--markliiRed);
    }


/* Medium screens */
@media all and (min-width: 600px) {

    .Container {
        padding: 5px;
        margin: 0px;
        flex-flow: row nowrap;
        justify-content: start;
        align-items: flex-start;
    }

    .Title {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }
    
    .Description {
        display: inherit;
        font-size: 10px;
        line-height: 16px;
    }

    .StepCircle {
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
    }

}

/* Large screens */
@media all and (min-width: 1000px) {

    .StepCircle {
        width: 28px;
        height: 28px;
    }
    
}
