
.ListItem {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    transition: transform 100ms ease-in-out;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 10px;
    margin: 5px 10px;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.15);
}

.Incomplete {
    border: 1px solid var(--markliiRed);
}

.PriceNotConfirmed {
    border: 1px solid var(--markliiBlue);
}

.ListItem:hover {
    transform: scale(1.01);
    transition: transform 100ms ease-in-out;
}

.Left {
    font-size: 10px;
    color: var(--lightGray);
    width: 10px;
}

.Middle {
    flex: 1 1 200px;
    box-sizing: border-box;
}

.Right {
    text-align: right;
}

.SmallDetails {
    font-size: 10px;
    margin-top: 2px;
    color: var(--lightGray);
}

.MiddleGap {
    flex: 1 0 10px;
    max-width: 10px;
    min-width: 10px;
    box-sizing: border-box;
}

.Selected {
    font-weight: 600;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.25);
    background-color: var(--markliiBlue);
    color: white;
}

.Selected * {
    color: #fff;
}

.PriceTagIcon {
    color: var(--markliiBlue);
    width: 16px;
    margin-left: 5px;
}

.NoPriceTag {
    width: 21px;
}