body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--darkGray);
  font-size: 0.8rem;
  font-family: "Helvetica Neue", "Open Sans", sans-serif;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  padding: 0;
  margin: 0;
  color: var(--darkGray);
  font-size: 23px;
  font-weight: 300;
  letter-spacing: 1.44px;
  line-height: 27px;
}

h3 {
  padding: 0;
  margin: 0;
  color: var(--darkGray);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.13px;
  line-height: 21px;
}

:root {
  --markliiGreen: #9ccd33;
  --markliiRed: #EE6A57;
  --markliiBlue: #4C8BFE;
  --markliiYellow: #F2AD14;
  --markliiOrange: #F09D31;
  --lightGray: #bdbdbd;
  --mediumGray: #a2a2a2;
  --darkGray: #777777;
}

fieldset {
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a[href^="http"] {
  color: var(--markliiGreen);
}

a[href^="http"]:hover {
  text-decoration: underline;
}

input[type=checkbox] {
  appearance: none;
  margin: 0;
  padding: 0;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border: 1px solid var(--lightGray);
  border-radius: 6px;
  transition: background-color 50ms linear;
  cursor: pointer;
}

input[type=checkbox]:checked {
  background-color: var(--markliiGreen);
  border-color: var(--markliiGreen); 
  transition: background-color 50ms linear;
}

input[type=checkbox]:focus {
  outline: none;
  border-color: var(--markliiGreen);
}

input[type=checkbox]:active {
  transform: scale(0.9);
  transition: transform 100ms linear;
}

/* Global CSS Classes */

.GradientBackground {
  background: rgb(103,207,75);
  background: linear-gradient(142deg, #67CF4B 10%, #b7d227 100%);
}

.Card {
  border-radius: 6px;
  background-color: #FFF;
  box-shadow: 1px 2px 10px 0 rgba(0,0,0,0.15);
  padding: 10px;
  box-sizing: border-box;
}