/* Mobile first */

.Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.Vertical {
    flex-flow: column nowrap;
}

/* Medium screens */
@media all and (min-width: 600px) {
    .Container {
        flex-flow: row wrap;
    }
}